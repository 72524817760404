import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import IhKC from "../../images/Footer/IHK-C.inline.svg"
import IhKP from "../../images/Footer/IHK-P.inline.svg"


const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            logoWildau: file(relativePath: { eq: "Footer/th-wildau.png" }) {
                childImageSharp {
                    fluid(maxWidth: 303, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            bmwi: file(relativePath: { eq: "Footer/BMWi_en.png" }) {
                childImageSharp {
                    fluid(maxWidth: 303, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            ITsicherheit: file(relativePath: { eq: "Footer/IT-Sicherheit.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 303, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            IHKOB: file(relativePath: { eq: "Footer/IHK-OB.png" }) {
                childImageSharp {
                    fluid(maxWidth: 246, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            DIZ: file(relativePath: { eq: "Footer/DIZ.png" }) {
                childImageSharp {
                    fluid(maxWidth: 246, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <footer>
            <div className="featured-companies">
                <div className="container">
                    <h3>Associated Partners:</h3>
                    <ul>
                        <li>
                            <a href="https://www.cottbus.ihk.de/" target="_blank" rel="noreferrer" aria-label="Link IHK Cottus">
                                <IhKC  className="image svg"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.ihk-ostbrandenburg.de/" target="_blank" rel="noreferrer" aria-label="Link IHK Cottus">
                                <Img className="image" fluid={data.IHKOB.childImageSharp.fluid} />
                            </a>
                        </li>
                        <li>
                            <a href="https://wis.ihk.de/ihk/potsdam.html" target="_blank" rel="noreferrer" aria-label="Link IHK Cottus">
                                <IhKP className="image svg" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.diz-bw.de/" target="_blank" rel="noreferrer" aria-label="Link IHK Cottus">
                                <Img className="image" fluid={data.DIZ.childImageSharp.fluid} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="footer">
                <div className="inner">
                    <div className="sponsors">
                        <div className="image wildau">
                            <Img fluid={data.logoWildau.childImageSharp.fluid} />
                        </div>
                        <div className="image bmwi">
                            <Img fluid={data.bmwi.childImageSharp.fluid} />
                        </div>
                        <div className="image it">
                            <Img fluid={data.ITsicherheit.childImageSharp.fluid} />
                        </div>
                    </div>
                    <div className="copyright">
                        <span>© Copyright <b>ALARM 2020</b></span> <br />
                        <span>All rights reserved.</span>
                        <br/> <br/> <br/>
                        <Link to="/en/impressum#impressum">Legal notice and privacy policy</Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer