import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React, {useContext} from "react"
import {GlobalDispatchContext, GlobalStateContext} from "../../context/GlobalContextProvider"

import menu from "../../images/Header/menu-bar.png"

const Header = () => {
    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);

    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "Header/alarm-logo-noSubs-trans.png" }) {
                childImageSharp {
                    fluid(maxWidth: 80, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            germanFlag: file(relativePath: { eq: "Header/german-flag.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 30, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    
    /* once LS and PUB content is added
        <Link to="/en/#learningScenarios" onClick={() => dispatch({type: "CHANGE_LS_ANALOG"})}>Analog Learning Scenarios</Link>
        <Link to="/en/#learningScenarios" onClick={() => dispatch({type: "CHANGE_LS_DIGITAL"})}>Digital Learning Scenarios</Link>
        <Link to="/en/#learningScenarios" onClick={() => dispatch({type: "CHANGE_LS_ONSITE"})}>On-Site-Attacks</Link>
        <Link className="last-child" to="/en/#learningScenarios" onClick={() => dispatch({type: "CHANGE_LS_SECURITY"})}>Security concepts</Link>

        <Link to="/en/#publications" onClick={() => dispatch({type: "CHANGE_PUB_PRESS"})}>Press</Link>
        <Link to="/en/#publications" onClick={() => dispatch({type: "CHANGE_PUB_ACTIVITIES"})}>Activities</Link>
        <Link to="/en/#publications" onClick={() => dispatch({type: "CHANGE_PUB_EVENTS"})}>Events</Link>
        <Link to="/en/#publications" onClick={() => dispatch({type: "CHANGE_PUB_FLYER"})}>Flyer & Brochures</Link>
        <Link to="/en/#publications" onClick={() => dispatch({type: "CHANGE_PUB_NETWORKING"})}>Networking</Link>
        <Link className="last-child" to="/en/#publications" onClick={() => dispatch({type: "CHANGE_PUB_PUBLICATIONS"})}>Scientific publications</Link>
    */

    return(
        <header>
            <div className="container">
                <div className="inner-header">
                    <div className="logo">
                        <Link to="/en/"><Img fluid={data.logo.childImageSharp.fluid}/></Link>
                    </div>
                    <nav className={state.navMenuActive}>
                        <ul>
                            <li><Link to="/en/#banner" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Home</Link></li>
                            <li><Link to="/en/#project" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Project</Link></li>
                            <li><Link to="/en/#learningScenarios" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Actions</Link>
                                <div className="dropdown-content">
                                    <Link to="/en/#learningScenarios" onClick={() => dispatch({type: "CHANGE_LS_ANALOG"})}>Analog Serious Games</Link>
                                    <Link to="/en/#learningScenarios" onClick={() => dispatch({type: "CHANGE_LS_DIGITAL"})}>Digital Serious Games</Link>                                  
                                    {/* <Link className="last-child" to="/en/#learningScenarios">Security concepts</Link> */}
                                </div>
                            </li>
                            <li><Link to="/en/#publications" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Public relations</Link>
                                <div className="dropdown-content">
                                    <Link to="/en/#publications" onClick={() => dispatch({type: "CHANGE_PUB_PRESS"})}>Press</Link>
                                    <Link to="/en/#publications"  onClick={() => dispatch({type: "CHANGE_PUB_EVENTS"})}>Events</Link>
                                    <Link to="/en/#publications" onClick={() => dispatch({type: "CHANGE_PUB_FLYER"})}>Flyer & Brochures</Link>
                                    <Link to="/en/#publications" onClick={() => dispatch({type: "CHANGE_PUB_NETWORKING"})}>Networking</Link>
                                    <Link className="last-child" to="/en/#publications" onClick={() => dispatch({type: "CHANGE_PUB_PUBLICATIONS"})}>Scientific publications</Link>
                                </div>
                            </li>
                            <li><Link to="/en/#team" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Team</Link></li>
                            <li><Link to="/en/#partner" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Partner</Link></li>
                            <li><Link to="/en/#contact" onClick={() => dispatch({type: "DEACTIVATE_NAVMENU"})}>Contact</Link></li>
                        </ul>
                    </nav>
                    <div className="language">
                        <Link to="/"><Img fluid={data.germanFlag.childImageSharp.fluid} className="german"/></Link>
                    </div>
                    <div className="menuToggle">
                        <button onClick={() => dispatch({type: "TOGGLE_NAVMENU"})}><img src={menu} alt="Menu" id="menu"/></button>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header
