
import React from 'react'

class Impressum extends React.Component {
    state = { 
        adress: "#partner"
    } 

    sendMail(mailName, domain, tld) {
        this.setState({
            adress: "mailto:" + mailName + "@" + domain + "." + tld
        });
    }
    render() {
        const mailAdress = this.state.adress;

    return (
            <div className="impressum-blurb" id="impressum">
                <div className="container">
                    <div className="inner-blurb"> 
                        <h1>Legal Notice</h1>
                        <p>
                            <b>Technical University of Applied Sciences Wildau</b> <br/>
                            Hochschulring 1 <br/>
                            15745 Wildau 
                        </p>

                        <h4>Contact:</h4>
                        <p>
                            <b>Prof. Margit Scholl</b><br/>
                            Project management for the project ALARM<br/>
                            Department: Business, Computing, and, Law<br/>
                            Tel.: +49 3375 508 917<br/>
                            Mail: <a href="mailto:margit.scholl@th-wildau.de">margit.scholl@th-wildau.de</a><br/>
                            Website: <a href="http://www.th-wildau.de/scholl">http://www.th-wildau.de/scholl</a><br/>
                            House 100, Room 106<br/><br/>

                            <b>Regina Schuktomow</b><br/>
                            Operational project management for the project ALARM<br/>
                            Tel.: +49 3375 508 239<br/>
                            Mail: <a href="mailto:regina.schuktomow@th-wildau.de">regina.schuktomow@th-wildau.de</a><br/>
                            House 100, Room 304</p>

                        <h2>Technical support</h2>
                        <p>
                            <b>Design and programming of the ALARM project website:</b><br/>
                            Peter Koppatz<br/>
                            Mail: <a href={mailAdress} className="cryptedmail"
                                            data-name="peter.koppatz"
                                            data-domain="th-wildau"
                                            data-tld="de"
                                            onClick={() => this.sendMail("peter.koppatz", "th-wildau", "de")}>
                                        </a> <br/><br/>
                            <b>Technical support for the ALARM project website:</b><br/>
                            Peter Ehrlich<br/>
                            Mail: <a href={mailAdress} className="cryptedmail"
                                            data-name="ernst-peter.ehrlich"
                                            data-domain="th-wildau"
                                            data-tld="de"
                                            onClick={() => this.sendMail("peter.koppatz", "th-wildau", "de")}>
                                        </a> <br/><br/>
                        </p>
                        
                        <h2>Disclaimer</h2>

                        <p>
                            This legal notice is only applicable to the documents on the web server of the project ALARM (alarm.wildau.biz) that link to this page. 
                            For all other pages on this web server, editorial responsibility rests with the respective bodies or persons who are listed on the website as responsible or who created the site.
                            <br/><br/>
                            The Technical University of Applied Sciences Wildau has no control over the content of the websites of other providers linked to from the web pages of the project ALARM. 
                            The operators of the other websites are exclusively responsible for the content of such pages. Although this content has been carefully inspected in accordance with legal requirements, 
                            the Technical University of Applied Sciences Wildau assumes no responsibility for the content of these links or the linked sites.
                        </p>

                        <h2>Copyright</h2>

                        <p>
                            The content and work on these pages created by the site operator are protected by German copyright. The reproduction, adaptation, dissemination of any kind, 
                            and every type of usage beyond the bounds of copyright law require the written consent of the respective author or creator. Downloading and copying this website is only permitted for private and not for commercial use.
                            <br/><br/>
                            Insofar as the contents of this page were not created by the operator, third-party copyrights will be observed. In particular, the content of third parties is identified as such. 
                            Should you still have reason to believe that a copyright violation has occurred, please notify us thereof. As soon as we become aware of a legal violation, we will remove such content immediately.
                        </p>

                        <h1>Privacy policy</h1>
                        <h2>1. Data protection at a glance</h2>
                        <h3>General information</h3>

                        <p>
                            The following information provides a simple overview of what happens with your personal data when you visit our website. 
                            Personal data is all data with which you can be personally identified. You can obtain detailed information on data protection from the data protection declaration presented beneath this text.
                        </p>

                        <h3>Data collection on our website</h3>

                        <h4>Who is responsible for collecting data on this website?</h4>

                        <p>The website operator processes data on this website. The operator’s contact details can be found in this website’s legal notice.</p>

                        <h4>How do we collect your data?</h4>

                        <p>
                            Your data is collected when you provide us with such information. This can be data that you enter into a contact form, for example.
                            <br /><br />
                            Other data is collected automatically by our IT systems when you visit the website. 
                            This is primarily technical data (e.g. Internet browser, operating system, and the time the page was viewed). This data is collected automatically as soon as you enter our website.
                        </p>

                        <h4>What do we use your data for?</h4>

                        <p>A part of your data is collected to guarantee the proper functioning of the website. Other data may be used to analyse your usage behaviour.</p>

                        <h4>What rights do you have with respect to your data?</h4>

                        <p>
                            You have the right to receive information free of charge about the origin, recipient, and purpose of your stored personal data at any time. You also have the right to demand the correction, blocking, 
                            or deletion of this data. If you have any questions about this or any other matter relating to data protection, you can contact us at any time at the address given in the legal notice. 
                            You also have a right to lodge a complaint with the responsible supervisory authority.
                        </p>

                        <h2>2. General notes and mandatory information</h2>
                        <h3>Data protection</h3>

                        <p>
                            The operators of this website take the protection of your personal data very seriously. 
                            We handle your personal data in confidence and in accordance with the statutory data protection regulations and with this data protection declaration. <br /><br />

                            A variety of personal data is collected when you use this website. Personal data is data with which you can be personally identified. 
                            This data protection declaration explains what data we collect and why we use it. It also explains how this happens and for what purpose. <br /><br />

                            We would like to point out that the transfer of data on the Internet (e.g. when communicating by email) may be vulnerable to security loopholes. Complete protection against access by third parties is not possible.
                        </p>

                        <h3>Information about the party responsible</h3>

                        <p>
                            The party responsible for data processing on this website is:<br /><br />

                            Peter Koppatz<br/>
                            Mail: <a href={mailAdress} className="cryptedmail"
                                            data-name="peter.koppatz"
                                            data-domain="th-wildau"
                                            data-tld="de"
                                            onClick={() => this.sendMail("peter.koppatz", "th-wildau", "de")}>
                                        </a> <br/><br/>

                            The party responsible is the natural person or legal entity who decides, alone or in conjunction with others, the purpose and means used for processing personal data (e.g. name, email addresses, etc.).
                        </p>
                        
                        <h3>Withdrawing your consent for data processing</h3>
                        <p>
                            Many data processing operations are only possible with your explicit consent. 
                            You can withdraw consent that you have already granted at any time. Informal notification by email will suffice for this purpose. This shall not affect the lawfulness of any data processing up to the point consent is withdrawn.
                        </p>

                        <h3>Right to lodge a complaint with the responsible supervisory authority</h3>
                        <p>
                            The affected party has the right to lodge a complaint with the responsible supervisory authority in the event of data protection breaches. 
                            The supervisory authority on data protection matters is the state data protection representative for the federal state, in which our company has its offices. 
                            You can find a list of data protection representatives and their contact details by following this link: 
                            <br/>
                            <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
                        </p>

                        <h3>Right to data portability</h3>
                        <p>
                            You have the right to arrange for the data which we process on the basis of your consent or in fulfilment of a contract to be handed over to you or to a third party in a standard, machine-readable format. 
                            If you ask for the data to be handed over directly to another responsible party, this will only take place insofar as it is technically feasible.
                        </p>
                        
                        <h3>SSL and TLS encryption</h3>
                        <p>
                            For security reasons and to protect confidential content, such as orders or enquiries, that you send to us as the website operator, this website uses SSL or TLS encryption. 
                            You can recognize an encrypted link by virtue of the fact that the address line of the browser changes from “http://” to “https://” and through the appearance of the padlock icon in your browser line.
                            <br/><br/>

                            If you have activated SSL or TLS encryption, the data that you have transmitted to us cannot be read by third parties.
                        </p>

                        <h3>Information, blocking, deletion</h3>
                        <p>
                            Within the scope of the applicable statutory provisions, you have the right at any time to obtain information free of charge about your stored personal data, its origin and recipients, 
                            and the purpose of data processing, and, if applicable, the right to correct, block, or delete such data. 
                            You can contact us at any time at the address given in the legal notice regarding this matter and any further questions you may have about data protection.
                        </p>
                        
                        <h3>Objection to advertising e-mails</h3>
                        <p>
                            We hereby object to the contact details provided as part of the legal notice being used to send any advertising and information material that has not been explicitly requested. 
                            The operators of the website reserve the right to take legal action against any unsolicited sending of advertising information, for example, through spam emails.
                        </p>
                        
                        <h2>3. Data protection representative</h2>
                        <h3>Statutory data protection officer</h3>

                        <p>
                            We have appointed a data protection officer for our project:<br/><br/>

                            <b>Gregor Klar</b><br/>
                            Mail: <a href={mailAdress} className="cryptedmail"
                                            data-name="datenschutz"
                                            data-domain="th-wildau"
                                            data-tld="de"
                                            onClick={() => this.sendMail("datenschutz", "th-wildau", "de")}>
                                        </a>
                        </p>

                        <h2>4. Data collection on our website</h2>
                        <h3>Cookies</h3>

                        <p>
                            Websites often make use of what are known as cookies. Cookies do not damage your computer and do not contain any viruses. Cookies help make our services more user-friendly, effective, and secure. 
                            Cookies are small text files which are that are placed on your computer and stored by your browser.
                            <br/><br/>
                            Most of the cookies we use are what are known as “session cookies”. They are automatically deleted after your visit. 
                            Other cookies remain stored on your device until you delete them. These cookies enable us to recognize your browser on your next visit.
                            <br/><br/>
                            You can set your browser to notify you if cookies are used and to allow cookies only on a case-by-case basis, to exclude the acceptance of cookies in certain cases or in general, 
                            and to activate automatic deletion of cookies upon closing your browser. Deactivating cookies may limit the functionality of this website.
                            <br/><br/>
                            Cookies that are required in order to carry out the processes of electronic communication or to provide certain functions requested by you (e.g. shopping basket functions) are stored in accordance with Art. 6(1)(f) GDPR. 
                            The website operator has a legitimate interest in storing cookies to provide technically error-free and optimized services. Insofar as other cookies (e.g. cookies for analysing your surfing behaviour) are stored, 
                            these are treated separately in this data protection declaration.
                            <br/><br/>
                        </p>

                        <h3>Server log files</h3>

                        <p>
                            The provider of the website collects and saves information automatically in "server log" files, which your browser transmits to us automatically.<br/><br/>

                            These are:
                            <ul>
                                <li>Type of browser and browser version</li>
                                <li>Operating system used</li>
                                <li>Referrer URL</li>
                                <li>Host name of the accessing computer</li>
                                <li>Time of the server request</li>
                                <li>IP address</li>
                            </ul>

                            This data is not merged with other data sources.<br/><br/>

                            Art. 6(1)(f) GDPR, which permits data to be processed in order to fulfil a contract or pre-contractual measures, is the basis for data processing.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Impressum

